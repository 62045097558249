<template>
  <div class="text-message">
    <span class="text-message-message">{{ message.text }}</span>
    <span class="text-message-date">
      {{ createdAtFormatted }}
    </span>
  </div>
</template>

<script>
import { format, parseISO } from 'date-fns'

export default {
  name: 'TextMessage',
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  computed: {
    createdAtFormatted() {
      return format(parseISO(this.message.createdAt), 'HH:mm')
    },
  },
}
</script>

<style lang="scss" scoped>
.text-message {
  overflow-wrap: break-word;
  max-width: 100%;
  position: relative;
  font-family: Muller, serif;
  margin: 5px 0;
  padding: 10px 50px 10px 10px;
  background: #fafafa;
  border-radius: 5px;
  &-message {
    font-size: 16px;
    color: #3e3e3e;
  }
  &-date {
    position: absolute;
    right: 10px;
    bottom: 5px;
    font-size: 12px;
    color: #bbbfbf;
  }
}
</style>
