import Vue from 'vue'
import JanusPlugin from '@doctis.front/library.vuecomponent.doctorcall'
import { AxiosUpdatedClient } from '@/services/core/axios/axios'
import { HubClientService } from '@/services/core/hub'

const apiClient = {
  client: AxiosUpdatedClient.instance,
  options: {
    baseUrl: process.env.VUE_APP_NEW_API,
  },
}

Vue.use(JanusPlugin, {
  apiClient,
  hubConnection: HubClientService,
})
