<template>
  <div class="call">
    <audio id="remote-audio" ref="remoteAudio" class="audio" autoplay></audio>

    <div class="content">
      <img
        :src="patientAvatar"
        :alt="consultation.Request.Profile.LastName"
        class="avatar"
      />
    </div>

    <div class="navigation">
      <div
        class="icon"
        :class="[isMicrophoneEnabled ? 'on' : 'off']"
        @click="toggleAudio"
      >
        <component
          :is="
            isMicrophoneEnabled ? 'IconMicrophone' : 'IconMicrophoneDisabled'
          "
        />
      </div>
      <div
        class="icon"
        :class="[isChat ? 'on' : 'off']"
        @click="setIsChat(!isChat)"
      >
        <component :is="isChat ? 'IconChat' : 'IconChatDisabled'"></component>
      </div>
      <div class="icon call-end" @click="onStopCall">
        <IconCallEnd />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import IconSettings from '@/assets/svg/settings.svg?inline'
import IconVideo from '@/assets/svg/video.svg?inline'
import IconVideoDisabled from '@/assets/svg/video-disabled.svg?inline'
import IconMicrophone from '@/assets/svg/microphone.svg?inline'
import IconMicrophoneDisabled from '@/assets/svg/microphone-disabled.svg?inline'
import IconChat from '@/assets/svg/chat.svg?inline'
import IconChatDisabled from '@/assets/svg/chat-disabled.svg?inline'
import IconCallEnd from '@/assets/svg/call-end.svg?inline'

export default {
  name: 'SipCall',
  components: {
    IconSettings,
    IconVideo,
    IconVideoDisabled,
    IconMicrophone,
    IconMicrophoneDisabled,
    IconChat,
    IconChatDisabled,
    IconCallEnd,
  },
  props: {
    remoteStream: {
      validator: () => true,
      required: true,
    },
    streamState: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(['isChat']),
    ...mapState('consultation', ['consultation']),
    isMicrophoneEnabled() {
      return this.streamState.audio
    },
    patientAvatar() {
      if (!this.consultation) {
        return null
      }

      const profile = this.consultation?.Request?.Profile

      if (profile && profile.Avatar) {
        return profile.Avatar
      }

      return require('@/assets/user-avatar-fallback.png')
    },
  },
  watch: {
    remoteStream: {
      handler(value) {
        if (value) {
          this.$nextTick(() => {
            const remoteAudio = this.$refs.remoteAudio
            remoteAudio.srcObject = this.remoteStream
          })
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(['setIsChat', 'setIsFullPage']),
    onStopCall() {
      this.$emit('hang-up')
      this.setIsFullPage(false)
    },
    toggleAudio() {
      this.$emit('update:stream-state', {
        audio: !this.streamState.audio,
        video: this.streamState.video,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.call {
  position: relative;
  width: 100%;

  .navigation {
    position: absolute;
    z-index: 2;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 88px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.72) 0%,
      rgba(0, 0, 0, 0) 86.76%
    );

    .icon {
      width: 42px;
      height: 42px;
      border-radius: 50px;
      margin: 0 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.2s;

      svg {
        width: 17px;
        height: 17px;
        fill: #ffffff;
      }

      &.on {
        background: #ffffff;

        svg {
          fill: #2e2e2e;
        }
      }

      &.off,
      &.settings {
        background: #ffffff33;

        &:hover {
          background: #ffffff47;
        }
      }

      &.call-end {
        background: #e30958;
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .avatar {
    position: absolute;

    width: 100px;
    height: 100px;
    top: calc(50% - 50px - 44px);
    left: calc(50% - 50px);
  }
}
</style>
