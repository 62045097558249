import Api from '@/api/api'

class CoreApi extends Api {
  constructor() {
    super()
    this.client.defaults.baseURL = process.env.VUE_APP_NEW_API
  }

  async getDoctorProfile() {
    const { data } = await this.client.get('/doctor/profile')
    return data
  }

  /**
   * @param {number} requestId
   * @param  {number} doctorSpecializationId
   * @return {Promise<any>}
   */
  async takeOnConsultation(requestId, doctorSpecializationId) {
    return await this.client.post('/consultation/takeOn', {
      requestId,
      doctorSpecializationId,
    })
  }

  auth(params) {
    return this.client.post('/v2/auth/doctor/', params)
  }
}

export default CoreApi
