import { coreRequest } from '@/api'
import { HubClientService } from '@/services/core/hub'

const Auth = {
  namespaced: true,
  state: {
    token: null,
    user: null,
  },
  actions: {
    async init({ state, dispatch, commit }, token) {
      commit('setToken', token)

      await dispatch('getProfile')
      if (state.user) {
        await HubClientService.init(token)
      }
    },
    async getProfile({ commit }) {
      const profile = await coreRequest.getDoctorProfile()
      commit('setUser', profile)
    },
  },
  mutations: {
    setUser(state, profile) {
      state.user = { ...profile }
    },
    setToken(state, token) {
      state.token = `Bearer ${token}`
    },
  },
}

export default Auth
