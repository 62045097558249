<template>
  <div v-if="isChat" class="header">
    <div class="patient">
      <component :is="iconPatient" class="icon" />
      <div class="name">{{ patientName }}</div>
    </div>

    <div v-if="isCallInProgress">
      <div class="navigation">
        <IconFullPage
          v-if="!isFullPage"
          class="icon"
          @click="setIsFullPage(true)"
        />
        <IconFixRight v-else class="icon" @click="setIsFullPage(false)" />
      </div>

      <!--      <div class="icon call-end">-->
      <!--        <IconCallEnd fill="#ffffff" width="24" height="24" />-->
      <!--      </div>-->

      <!--      <div class="icon call-end" @click="toggleChat">-->
      <!--        <IconChat fill="#ffffff" width="24" height="24" />-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import IconPatientWoman from '@/assets/svg/female.svg?inline'
import IconPatientMan from '@/assets/svg/male.svg?inline'
import IconFullPage from '@/assets/svg/fix-full.svg?inline'
import IconFixRight from '@/assets/svg/fix-right.svg?inline'
import IconFlexWrap from '@/assets/svg/flex-wrap.svg?inline'
import IconWrap from '@/assets/svg/wrap.svg?inline'
import IconCallEnd from '@/assets/svg/call-end.svg?inline'
import IconChat from '@/assets/svg/chat.svg?inline'

export default {
  name: 'Header',
  components: {
    IconPatientWoman,
    IconPatientMan,
    IconFullPage, // Раскрыть на полную
    IconFlexWrap, // Справа внизу
    IconFixRight,
    IconWrap, // Свернуть
    IconCallEnd,
    IconChat,
  },
  props: {
    isCallInProgress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMan: true,
    }
  },
  computed: {
    ...mapState(['isChat', 'isFullPage', 'isFixRight', 'isWrap']),
    ...mapState('consultation', ['consultation']),
    iconPatient() {
      return this.isMan ? 'IconPatientWoman' : 'IconPatientMan'
    },
    patientName() {
      if (!this.consultation?.Request?.Profile) {
        return ''
      }
      const { LastName, FirstName, MiddleName } =
        this.consultation.Request.Profile

      return [LastName, FirstName, MiddleName].filter((str) => str).join(' ')
    },
  },
  methods: {
    ...mapMutations(['setIsFullPage', 'setIsWrap', 'setIsChat']),
    toggleChat() {
      this.setIsChat(!this.isChat)
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 15px;
  color: #ffffff;
  background: #2e2e2e;

  .patient {
    display: flex;
    align-items: center;

    .icon {
      width: 20px;
      height: 20px;
      fill: #ffffff;
    }

    .name {
      font-size: 15px;
      margin: 0 5px;
    }

    .timer {
      font-size: 13px;
      color: #989898;
    }
  }

  .navigation {
    display: flex;
    align-items: center;

    .icon {
      cursor: pointer;
      width: 20px;
      height: 20px;
      fill: #ffffff;
      margin-left: 20px;

      &:first-child {
        margin-left: 0;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.call-end {
  width: 36px;
  height: 36px;
  border-radius: 50px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
  background: #e30958;
  svg {
    fill: #ffffff;
  }
}
</style>
