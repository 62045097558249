var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[(_vm.consultation)?_c('div',[_c('doctor-call',{attrs:{"user-id":_vm.user.doctorId,"user-name":_vm.user.fullName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var options = ref.options;
var actions = ref.actions;
return [_c('div',{staticClass:"wrapper",class:{
          'full-page': _vm.isFullPage && options.isCallInProgress,
          'is-chat': _vm.isChat,
          wrap: _vm.isWrap,
        },style:({
          bottom:
            !options.isCalling && !options.isCallInProgress ? '90px' : '30px',
        })},[_c('Header',{attrs:{"is-call-in-progress":options.isCallInProgress}}),(options.isCalling)?_c('Calling',{on:{"stop":actions.hangUp}}):(options.isCallInProgress)?[(options.isVideoCall)?_c('VideoCall',{attrs:{"remote-stream":options.remoteStream,"stream-state":options.streamState},on:{"hang-up":actions.hangUp,"update:stream-state":actions.updateStreamState}}):(options.isAudioCall)?_c('AudioCall',{attrs:{"remote-stream":options.remoteStream,"stream-state":options.streamState},on:{"hang-up":actions.hangUp,"update:stream-state":actions.updateStreamState}}):(options.isSIPCall)?_c('SipCall',{attrs:{"remote-stream":options.remoteStream,"stream-state":options.streamState},on:{"hang-up":actions.hangUp,"update:stream-state":actions.updateStreamState}}):_vm._e()]:_vm._e(),(
            _vm.isChat &&
            _vm.consultation &&
            _vm.consultation.Request.Status !== 0 &&
            !options.isCalling
          )?_c('ConsultationChat'):_vm._e(),(!options.isCalling && !options.isCallInProgress)?_c('div',{staticClass:"widget-preview"},[(_vm.consultation.Request.Status === 0)?_c('div',{staticClass:"widget-preview-take"},[_c('div',[_vm._v(" Консультация с пациентом: "),_c('br'),_c('strong',[_c('i',[_vm._v(_vm._s(_vm.patientFullName))])])]),_c('button',{on:{"click":_vm.takeOn}},[_vm._v("Взять в работу")])]):_c('div',{staticClass:"widget-preview"},_vm._l((_vm.callingControls),function(item,index){return _c('div',{key:index,staticClass:"call-item",on:{"click":item.handler}},[_c(("icon-" + (item.icon)),{tag:"component",attrs:{"fill":"#e73771","width":"24","height":"24"}})],1)}),0)]):_vm._e()],2)]}}],null,false,892953124)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }