<template>
  <div
    v-if="file"
    class="file-message"
    :title="file.OriginName"
    @click="selectFile"
  >
    <div class="file-message-preview">
      <template v-if="file.MimeType.includes('image')">
        <img alt="Изображение" :src="file.base64" />
      </template>
      <template v-else>
        <component :is="fileIcon" width="48" height="48" />
      </template>
    </div>
    <div class="file-message-content">
      <div class="file-message-content__filename">
        {{ file.OriginName }}
      </div>
      <div class="file-message-content-info">
        <span class="file-message-content-info__ext">{{ ext }}</span>
        <span class="file-message-content-info__size">{{ totalSize }}</span>
      </div>
    </div>
    <div class="file-message-date">
      {{ createdAtFormatted }}
    </div>
  </div>
</template>

<script>
import { format, parseISO } from 'date-fns'

import MP4Icon from '@/assets/svg/file/mp4.svg?inline'
import MP3Icon from '@/assets/svg/file/mp3.svg?inline'
import PDFIcon from '@/assets/svg/file/pdf.svg?inline'
import DefaultIcon from '@/assets/svg/file/default.svg?inline'

import { openFile } from '@/helpers'

export default {
  name: 'FileMessage',
  components: { MP3Icon, MP4Icon, PDFIcon, DefaultIcon },
  props: {
    message: {
      type: Object,
      required: true,
    },
    files: {
      type: Array,
      required: true,
    },
  },
  computed: {
    /**
     * @returns {ChatFileMessagePayload}
     */
    payload() {
      return JSON.parse(this.message.payload)
    },
    file() {
      const { fileNames } = this.payload
      return this.files.find((file) => file.Name === fileNames[0])
    },
    totalSize() {
      const b = this.file.TotalSize
      const kb = b / 1000
      const mb = kb / 1000
      if (b < 1000) return `${b} B`
      if (kb < 1000) return `${kb.toFixed(2)} KB`
      return `${mb.toFixed(2)} MB`
    },
    ext() {
      return this.file.Extension.slice(1).toUpperCase()
    },
    createdAtFormatted() {
      return format(parseISO(this.message.createdAt), 'HH:mm')
    },
    fileIcon() {
      let fileComponent = ''

      if (this.ext === 'MP3' || this.ext === 'MP4' || this.ext === 'PDF') {
        fileComponent += this.ext
      }
      if (!fileComponent) fileComponent += 'Default'

      return `${fileComponent}-icon`
    },
  },
  methods: {
    selectFile() {
      openFile(this.file)
    },
  },
}
</script>

<style lang="scss" scoped>
.file-message {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  font-family: Muller, serif;
  margin: 5px 0;
  padding: 10px 50px 10px 10px;
  background: #fafafa;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    user-select: none;
  }

  &-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 48px;
    width: 48px;
    height: 48px;
    margin-right: 10px;
    img {
      flex: 0 0 48px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 42px);
    height: 100%;
    &__filename {
      font-size: 14px;
      color: #2c2c2c;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-info {
      margin-top: 5px;
      font-size: 10px;
      color: #3d3d3d;

      span {
        margin-right: 5px;
      }
    }
  }
  &-date {
    position: absolute;
    right: 10px;
    bottom: 5px;
    font-size: 12px;
    color: #b1b1b1;
  }
  &__error {
    color: #e30958;
  }
}
</style>
