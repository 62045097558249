import axios from 'axios'
import store from '@/store'

class AxiosClient {
  constructor($axios) {
    this.instance = $axios

    this.instance.interceptors.request.use((config) => {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: store.state.auth.token,
        },
      }
    })

    this.instance.interceptors.response.use((response) => {
      return response.data.data
    })
  }
}

export const AxiosClientService = new AxiosClient(axios.create())
