export default function (value, format) {
  const date = new Date(value)

  let options = {}

  if (format.includes('second')) {
    options = { ...options, minute: '2-digit', second: '2-digit' }
  }

  if (format.includes('time')) {
    options = { ...options, hour: '2-digit', minute: '2-digit' }
  }

  if (format.includes('date')) {
    options = { ...options, day: '2-digit', month: '2-digit', year: 'numeric' }
  }

  if (format.includes('monthlong')) {
    options = { ...options, month: 'long' }
  }

  return new Intl.DateTimeFormat('ru-Ru', options).format(date)
}
