import { audioSounds } from '@/consts/const'

export const fakeDataChat = [
  {
    id: '2fc91c37-067c-426a-87cd-5a5ccf7eedea',
    type: 1000,
    text: 1628024400000,
    timetoken: 1628024400000,
  },
  [
    {
      text: 'Привет от Врача',
      userId: 1227644,
      messageType: 100,
      messageId: '40cab65f-f05e-4ae7-8ff1-99c3bc048753',
      id: '40cab65f-f05e-4ae7-8ff1-99c3bc048753',
      type: 100,
      timetoken: 1628076356832,
    },
  ],
  [
    {
      text: 'Привет от Пациента',
      userId: 1127076,
      messageType: 100,
      messageId: '8207c5ca-aa0d-4deb-afff-09dd59fef4f3',
      id: '8207c5ca-aa0d-4deb-afff-09dd59fef4f3',
      type: 100,
      timetoken: 1628076403421,
    },
  ],
  {
    text: '',
    userId: 1227644,
    messageType: 400,
    messageId: 'eedacc5f-6b5d-4085-a1cf-c7e7c50a35fc',
    id: 'eedacc5f-6b5d-4085-a1cf-c7e7c50a35fc',
    type: 400,
    timetoken: 1628076413163,
  },
  [
    {
      text: 'Пропущенный звонок',
      userId: 1227644,
      messageType: 100,
      messageId: 'ad944fe3-8737-4208-a1bd-5f5d8d733a16',
      id: 'ad944fe3-8737-4208-a1bd-5f5d8d733a16',
      type: 100,
      timetoken: 1628076449383,
    },
  ],
  {
    text: '(00:27) ',
    userId: 1227644,
    messageType: 300,
    messageId: '893d3b7f-f890-4ad6-9adf-9551814d12cb',
    id: '893d3b7f-f890-4ad6-9adf-9551814d12cb',
    type: 300,
    timetoken: 1628076528656,
  },
  [
    {
      text: 'Входящий звонок. 27 секунд',
      userId: 1227644,
      messageType: 100,
      messageId: '29dac0d4-367a-47e0-8427-9e889d0bfb6b',
      id: '29dac0d4-367a-47e0-8427-9e889d0bfb6b',
      type: 100,
      timetoken: 1628076551592,
    },
  ],
  {
    text: '(01:42) ',
    userId: 1227644,
    messageType: 300,
    messageId: '78f92719-708b-4ca3-97bd-ceca46265b70',
    id: '78f92719-708b-4ca3-97bd-ceca46265b70',
    type: 300,
    timetoken: 1628076708159,
  },
  [
    {
      text: 'аудиозвонок',
      userId: 1127076,
      messageType: 100,
      messageId: '5c5187d9-adc0-427e-8b87-cbe38809a4f6',
      id: '5c5187d9-adc0-427e-8b87-cbe38809a4f6',
      type: 100,
      timetoken: 1628076739309,
    },
    {
      text: 'Давно выяснено, что при оценке дизайна и композиции читаемый текст мешает сосредоточиться.',
      userId: 1127076,
      messageType: 100,
      messageId: 'ec735172-caa1-4dd7-86ef-c26736941c9e',
      id: 'ec735172-caa1-4dd7-86ef-c26736941c9e',
      type: 100,
      timetoken: 1628076802316,
    },
  ],
  [
    {
      text: 'Есть много вариантов Lorem Ipsum, но большинство из них имеет не всегда приемлемые модификации, например, юмористические вставки или слова, которые даже отдалённо не напоминают латынь.',
      userId: 1227644,
      messageType: 100,
      messageId: 'eb89c082-82b3-4aed-a73e-7ca8fda1cc04',
      id: 'eb89c082-82b3-4aed-a73e-7ca8fda1cc04',
      type: 100,
      timetoken: 1628076819130,
    },
  ],
  {
    text: '',
    userId: 1227644,
    messageType: 400,
    messageId: 'fa7ac041-4d9f-4368-9300-05f9f50a8e9d',
    id: 'fa7ac041-4d9f-4368-9300-05f9f50a8e9d',
    type: 400,
    timetoken: 1628076868520,
  },
  [
    {
      text: 'Отклоненный видеовызов',
      userId: 1227644,
      messageType: 100,
      messageId: '6793a576-7aff-4517-b178-899d9a5c5a35',
      id: '6793a576-7aff-4517-b178-899d9a5c5a35',
      type: 100,
      timetoken: 1628076905096,
    },
  ],
  {
    text: '',
    userId: 1227644,
    messageType: 400,
    messageId: 'c96a83c4-9767-4733-9d11-98e291af69ed',
    id: 'c96a83c4-9767-4733-9d11-98e291af69ed',
    type: 400,
    timetoken: 1628076913820,
  },
  [
    {
      text: 'Отклоненный аудиовызов',
      userId: 1227644,
      messageType: 100,
      messageId: '7c8ba74c-613a-4598-a2c3-9c5ddfb8b319',
      id: '7c8ba74c-613a-4598-a2c3-9c5ddfb8b319',
      type: 100,
      timetoken: 1628076936111,
    },
  ],
  {
    text: '(00:09) ',
    userId: 1227644,
    messageType: 300,
    messageId: 'b1f7a3a4-62a4-43c6-ab71-398a4d446438',
    id: 'b1f7a3a4-62a4-43c6-ab71-398a4d446438',
    type: 300,
    timetoken: 1628076956900,
  },
  [
    {
      text: 'Исходящий видеовызов',
      userId: 1227644,
      messageType: 100,
      messageId: '66cbd453-a30c-450c-b785-9239da909868',
      id: '66cbd453-a30c-450c-b785-9239da909868',
      type: 100,
      timetoken: 1628077001053,
    },
  ],
  {
    text: '',
    userId: 1227644,
    messageType: 400,
    messageId: '4652f29c-b1b3-49fe-aedf-00c1e14636ea',
    id: '4652f29c-b1b3-49fe-aedf-00c1e14636ea',
    type: 400,
    timetoken: 1628077003250,
  },
  {
    text: '',
    userId: 1227644,
    messageType: 400,
    messageId: 'b9404957-e559-40de-9904-3df41be6ea75',
    id: 'b9404957-e559-40de-9904-3df41be6ea75',
    type: 400,
    timetoken: 1628077024926,
  },
  [
    {
      text: 'Отклоненный аудиовызов\n\n',
      userId: 1227644,
      messageType: 100,
      messageId: '6b5decec-c19c-4598-bdd2-773564548089',
      id: '6b5decec-c19c-4598-bdd2-773564548089',
      type: 100,
      timetoken: 1628077035155,
    },
  ],
  {
    text: '(00:16) ',
    userId: 1227644,
    messageType: 300,
    messageId: '2d6bdf9a-490b-4186-8605-28a509fe43f8',
    id: '2d6bdf9a-490b-4186-8605-28a509fe43f8',
    type: 300,
    timetoken: 1628077057099,
  },
  [
    {
      text: 'Исходящий аудиовызов\n\n',
      userId: 1227644,
      messageType: 100,
      messageId: '34432f0e-dab5-4f10-bf8e-b9fa5aad69ae',
      id: '34432f0e-dab5-4f10-bf8e-b9fa5aad69ae',
      type: 100,
      timetoken: 1628077069032,
    },
    {
      text: 'текстовое сообщение 1111',
      userId: 1227644,
      messageType: 100,
      messageId: '1c292822-5eb6-489c-80a9-fe6f5f75f77e',
      id: '1c292822-5eb6-489c-80a9-fe6f5f75f77e',
      type: 100,
      timetoken: 1628077651183,
    },
    {
      text: 'текстовое сообщение 2222',
      userId: 1227644,
      messageType: 100,
      messageId: 'ab85196a-9acb-4866-a1b7-95a52404d0ad',
      id: 'ab85196a-9acb-4866-a1b7-95a52404d0ad',
      type: 100,
      timetoken: 1628077656196,
    },
    {
      text: 'текстовое сообщение 3333',
      userId: 1227644,
      messageType: 100,
      messageId: 'c482ebbf-b65a-47d2-9588-dcd2ef75c73b',
      id: 'c482ebbf-b65a-47d2-9588-dcd2ef75c73b',
      type: 100,
      timetoken: 1628077662468,
    },
    {
      text: 'текстовое сообщение 4444',
      userId: 1227644,
      messageType: 100,
      messageId: 'de6eb653-5c3c-4c21-8331-476d23070664',
      id: 'de6eb653-5c3c-4c21-8331-476d23070664',
      type: 100,
      timetoken: 1628077667026,
    },
    {
      text: 'текстовое сообщение 5555',
      userId: 1227644,
      messageType: 100,
      messageId: '55c31cad-e4a2-4041-aa6b-2715e25f3489',
      id: '55c31cad-e4a2-4041-aa6b-2715e25f3489',
      type: 100,
      timetoken: 1628077672143,
    },
    {
      text: 'текстовое сообщение 6666',
      userId: 1227644,
      messageType: 100,
      messageId: 'c22b4f78-3c97-4a03-b58c-cc2a0d41d5d6',
      id: 'c22b4f78-3c97-4a03-b58c-cc2a0d41d5d6',
      type: 100,
      timetoken: 1628077676603,
    },
  ],
]

export const convertBlobToBase64 = async (blob) => {
  const reader = new FileReader()
  reader.readAsDataURL(blob)

  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result)
    }
  })
}

export const stopAudioSound = (sound) => {
  // document.querySelector(sound).pause()
}

export const startAudioSound = (sound) => {
  // Object.values(audioSounds).forEach((audioSound) => {
  //   document.querySelector(audioSound).pause()
  // })
  // const soundTrack = document.querySelector(sound)
  // soundTrack.currentTime = 0
  // soundTrack.play()
}

export const createBlobFromArrayBuffer = (buffer, mimeType) => {
  return new Blob([buffer], { type: mimeType })
}

export const openFile = (file) => {
  const url = window.URL.createObjectURL(file.blob)
  Object.assign(document.createElement('a'), {
    target: '_blank',
    href: url,
    download: file.OriginName,
  }).click()
  setTimeout(() => {
    window.URL.revokeObjectURL(url)
  })
}
