<template>
  <div id="app">
    <div v-if="consultation">
      <doctor-call
        v-slot="{ options, actions }"
        :user-id="user.doctorId"
        :user-name="user.fullName"
      >
        <div
          class="wrapper"
          :style="{
            bottom:
              !options.isCalling && !options.isCallInProgress ? '90px' : '30px',
          }"
          :class="{
            'full-page': isFullPage && options.isCallInProgress,
            'is-chat': isChat,
            wrap: isWrap,
          }"
        >
          <Header :is-call-in-progress="options.isCallInProgress" />

          <Calling v-if="options.isCalling" @stop="actions.hangUp" />
          <template v-else-if="options.isCallInProgress">
            <VideoCall
              v-if="options.isVideoCall"
              :remote-stream="options.remoteStream"
              :stream-state="options.streamState"
              @hang-up="actions.hangUp"
              @update:stream-state="actions.updateStreamState"
            />
            <AudioCall
              v-else-if="options.isAudioCall"
              :remote-stream="options.remoteStream"
              :stream-state="options.streamState"
              @hang-up="actions.hangUp"
              @update:stream-state="actions.updateStreamState"
            />
            <SipCall
              v-else-if="options.isSIPCall"
              :remote-stream="options.remoteStream"
              :stream-state="options.streamState"
              @hang-up="actions.hangUp"
              @update:stream-state="actions.updateStreamState"
            />
          </template>

          <ConsultationChat
            v-if="
              isChat &&
              consultation &&
              consultation.Request.Status !== 0 &&
              !options.isCalling
            "
          />

          <div
            v-if="!options.isCalling && !options.isCallInProgress"
            class="widget-preview"
          >
            <div
              v-if="consultation.Request.Status === 0"
              class="widget-preview-take"
            >
              <div>
                Консультация с пациентом: <br />
                <strong>
                  <i>{{ patientFullName }}</i>
                </strong>
              </div>
              <button @click="takeOn">Взять в работу</button>
            </div>

            <div v-else class="widget-preview">
              <div
                v-for="(item, index) in callingControls"
                :key="index"
                class="call-item"
                @click="item.handler"
              >
                <component
                  :is="`icon-${item.icon}`"
                  fill="#e73771"
                  width="24"
                  height="24"
                />
              </div>
            </div>
          </div>
        </div>
      </doctor-call>
    </div>
  </div>
</template>

<script>
import store from './store/index'
import { mapActions, mapMutations, mapState } from 'vuex'

import Header from '@/components/Header'
import ConsultationChat from '@/components/chat/ConsultationChat'
import Calling from '@/components/call/Calling.vue'
import VideoCall from '@/components/call/VideoCall.vue'
import AudioCall from '@/components/call/AudioCall.vue'
import SipCall from '@/components/call/SipCall.vue'

import IconVideo from '@/assets/svg/video.svg?inline'
import IconCall from '@/assets/svg/call.svg?inline'
import IconSip from '@/assets/svg/sip.svg?inline'
import IconChat from '@/assets/svg/chat.svg?inline'

import { coreRequest } from '@/api'
import { CALL_TYPES } from '@/consts/const'

export default {
  name: 'App',
  store: store,
  components: {
    SipCall,
    VideoCall,
    AudioCall,
    Calling,
    Header,
    ConsultationChat,

    IconVideo,
    IconCall,
    IconSip,
    IconChat,
  },
  data() {
    return {
      callingControls: [
        {
          icon: 'video',
          handler: this.callWithVideo,
        },
        {
          icon: 'call',
          handler: this.callWithAudio,
        },
        {
          icon: 'sip',
          handler: this.callWithSIP,
        },
        {
          icon: 'chat',
          handler: this.openChat,
        },
      ],
    }
  },
  computed: {
    ...mapState(['isChat', 'isFullPage', 'isWrap']),
    ...mapState('consultation', ['consultation', 'userProfile']),
    ...mapState('auth', ['user']),
    patientFullName() {
      if (!this.consultation) {
        return ''
      }

      const { FirstName, LastName, MiddleName } =
        this.consultation.Request.Profile

      return `${LastName} ${FirstName} ${MiddleName}`
    },
    sipCallingInfo() {
      const { Id, Avatar, FirstName, LastName, MiddleName } =
        this.consultation.Request.Profile

      const fullName = `${LastName}, ${FirstName} ${MiddleName}`

      const member = {
        id: Id,
        firstName: FirstName,
        lastName: LastName,
        middleName: MiddleName,
        fullName,
        avatar: Avatar,
        type: 'Patient',
        specialization: null,
      }

      return {
        callerId: this.user.doctorId,
        consultationId: this.consultation.Request.Id,
        callType: CALL_TYPES.SIP,
        members: [member],
      }
    },
    userPhone() {
      const { PhoneNumber, ContactPhoneNumber } = this.userProfile

      return PhoneNumber || ContactPhoneNumber
    },
  },
  async mounted() {
    if (process.env.VUE_APP_ENVIRONMENT === 'development') {
      const queryString = new URLSearchParams(window.location.search)
      const token = queryString.get('token')
      const consultationId = queryString.get('consultation_id')

      const params = { token, consultation_id: consultationId }

      if (window.dwidget && token && consultationId) {
        // eslint-disable-next-line no-undef
        await dwidget.initiate(params)
      }
    }
  },
  methods: {
    ...mapActions('auth', {
      initAuth: 'init',
    }),
    ...mapActions('consultation', ['getConsultationById']),
    ...mapMutations('consultation', ['setConsultation']),
    ...mapMutations(['setCallType', 'setIsChat']),
    callWithVideo() {
      this.$janusService.makeCall({
        callType: CALL_TYPES.VIDEO,
        consultationId: this.consultation.Request.Id,
      })
    },
    callWithAudio() {
      this.$janusService.makeCall({
        callType: CALL_TYPES.AUDIO,
        consultationId: this.consultation.Request.Id,
      })
    },
    callWithSIP() {
      this.$sipService.makeCall(this.userPhone, this.sipCallingInfo)
    },
    async takeOn() {
      await coreRequest.takeOnConsultation(
        this.consultation.Request.Id,
        this.consultation.Request.TargetDoctorSpecializationId
      )

      await this.getConsultationById(this.consultation.Request.Id)
    },
    openChat() {
      this.setIsChat(!this.isChat)
    },
  },
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

* {
  box-sizing: border-box;
}

#app {
  font-family: 'Roboto', sans-serif;
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 3px;
    border-radius: 100%;
  }

  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d0d0d0;
  }
}
.wrapper {
  position: absolute;
  bottom: 70px;
  right: 0;
  width: 100%;
  box-shadow: 0 5px 11px #45454530;

  @media (min-width: 576px) {
    width: 400px;
    right: 30px;
  }

  .header {
    height: 48px;
  }

  .call {
    height: 300px;
  }

  .chat {
    height: 350px;
  }

  &.full-page {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    width: 100%;
    height: 100%;
    bottom: 0 !important;
    right: 0;
    margin: 0;
    border-radius: 0;

    background: #333333;

    &.is-chat {
      @media (max-width: 992px) {
        .call {
          height: calc(50% - 50px) !important;
        }
        .chat {
          height: 50% !important;
        }
      }
    }

    @media (max-width: 992px) {
      flex-direction: column;
      flex-wrap: initial;

      .call {
        width: 100% !important;
      }
      .chat {
        position: relative;
        width: 100% !important;
      }
    }

    .call {
      width: 100%;
      height: calc(100% - 48px);
    }

    .chat {
      width: 450px;
      height: calc(100% - 48px);

      .chat-inner {
        height: 100%;
      }
    }

    &.is-chat {
      .call {
        width: calc(100% - 450px);
      }

      .chat {
        background: #ffffff;
      }

      .header {
        position: absolute;
        bottom: 0;
      }
    }
  }

  &.wrap {
    .call {
      height: 88px;

      .navigation {
        background: #333333;
      }

      .test-image {
        display: none;
      }
    }
  }
}

.widget-preview {
  position: fixed;
  bottom: 30px;
  right: 30px;

  display: flex;

  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  border-radius: 5px;

  .widget-preview-take {
    padding: 10px;

    button {
      display: block;
      margin-top: 10px;
      margin-left: auto;
      margin-right: auto;

      background: #e73771;
      border: none;
      color: #ffffff;
      padding: 0 20px;

      height: 30px;
      border-radius: 5px;

      cursor: pointer;

      transition: all ease-in-out 0.2s;

      &:hover {
        opacity: 0.7;
      }

      &:active {
        opacity: 1;
      }
    }
  }

  .call-item {
    padding: 10px;

    transition: all ease-in-out 0.2s;

    &:hover {
      background-color: #dcdcdc;
      cursor: pointer;
    }
  }
}
</style>
