<template>
  <div class="call">
    <div class="video-wrapper">
      <video ref="remoteVideo" autoplay playsinline></video>
    </div>

    <div class="navigation">
      <div
        class="icon"
        :class="[isVideoEnabled ? 'on' : 'off']"
        @click="toggleVideo"
      >
        <component
          :is="isVideoEnabled ? 'IconVideo' : 'IconVideoDisabled'"
          fill="#ffffff"
          width="16"
          height="16"
        ></component>
      </div>
      <div
        class="icon"
        :class="[isMicrophoneEnabled ? 'on' : 'off']"
        @click="toggleAudio"
      >
        <component
          :is="
            isMicrophoneEnabled ? 'IconMicrophone' : 'IconMicrophoneDisabled'
          "
          fill="#ffffff"
          width="16"
          height="16"
        />
      </div>
      <div
        class="icon"
        :class="[isChat ? 'on' : 'off']"
        @click="setIsChat(!isChat)"
      >
        <component
          :is="isChat ? 'IconChat' : 'IconChatDisabled'"
          fill="#ffffff"
          width="16"
          height="16"
        ></component>
      </div>
      <div class="icon call-end" @click="onStopCall">
        <IconCallEnd fill="#ffffff" width="24" height="24" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import IconSettings from '@/assets/svg/settings.svg?inline'
import IconVideo from '@/assets/svg/video.svg?inline'
import IconVideoDisabled from '@/assets/svg/video-disabled.svg?inline'
import IconMicrophone from '@/assets/svg/microphone.svg?inline'
import IconMicrophoneDisabled from '@/assets/svg/microphone-disabled.svg?inline'
import IconChat from '@/assets/svg/chat.svg?inline'
import IconChatDisabled from '@/assets/svg/chat-disabled.svg?inline'
import IconCallEnd from '@/assets/svg/call-end.svg?inline'

export default {
  name: 'VideoCall',
  components: {
    IconSettings,
    IconVideo,
    IconVideoDisabled,
    IconMicrophone,
    IconMicrophoneDisabled,
    IconChat,
    IconChatDisabled,
    IconCallEnd,
  },
  props: {
    remoteStream: {
      validator: () => true,
      required: true,
    },
    streamState: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(['isChat']),
    isMicrophoneEnabled() {
      return this.streamState.audio
    },
    isVideoEnabled() {
      return this.streamState.video
    },
  },
  watch: {
    remoteStream: {
      handler(value) {
        if (value) {
          this.$nextTick(() => {
            const remoteVideo = this.$refs.remoteVideo
            remoteVideo.srcObject = this.remoteStream
          })
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(['setIsChat', 'setIsFullPage']),
    onStopCall() {
      this.$emit('hang-up')
      this.setIsFullPage(false)
    },
    toggleAudio() {
      this.$emit('update:stream-state', {
        audio: !this.streamState.audio,
        video: this.streamState.video,
      })
    },
    toggleVideo() {
      this.$emit('update:stream-state', {
        audio: this.streamState.audio,
        video: !this.streamState.video,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.call {
  position: relative;
  width: 100%;

  .navigation {
    position: absolute;
    z-index: 2;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.72) 0%,
      rgba(0, 0, 0, 0) 86.76%
    );

    .icon {
      width: 28px;
      height: 28px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.2s;

      background: #5b6165;

      &:not(:last-child) {
        margin-right: 15px;
      }

      &.off {
        background: #ffffff;
        svg {
          fill: #5b6165;
        }
      }

      &.call-end {
        margin-left: 10px;

        height: 36px;
        width: 36px;
        background: #e30958;
      }
    }
  }

  .video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    video {
      display: block;
      background-size: cover;
      background-position: 50%;
      margin: 0 auto;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
