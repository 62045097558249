export const messageTypes = {
  TEXT_MESSAGE: 100,
  COMPLETED_CALL: 300,
  START_CALL: 310,
  MISSED_CALL: 400,
  ATTACHMENT: 500,
  TIMESTAMP: 1000,
}

export const textMessageTypes = [
  messageTypes.TEXT_MESSAGE,
  messageTypes.ATTACHMENT,
]

export const timestampMessageTypes = [
  messageTypes.COMPLETED_CALL,
  messageTypes.MISSED_CALL,
  messageTypes.TIMESTAMP,
]

export const fileCategories = ['image', 'audio', 'video', 'document']

export const audioSounds = {
  CALLING_SOUND: '#calling-sound',
  NOT_AVAILABLE_CALLING_SOUND: '#not-available-sound',
}

export const fileTypes = {
  ANALYSIS: {
    id: 1,
    title: 'Анализы',
  },
  RESEARCH: {
    id: 2,
    title: 'Исследования',
  },
  INSPECTION: {
    id: 3,
    title: 'Осмотры',
  },
  OTHER: {
    id: 4,
    title: 'Прочее',
  },
}

export const CALL_TYPES = {
  VIDEO: 'Video',
  AUDIO: 'Audio',
  SIP: 'SIP',
}
