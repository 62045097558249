<template>
  <div class="timestamp-message">
    <span class="timestamp-message-message">
      {{ textMessage }}
    </span>
  </div>
</template>

<script>
import { format, parseISO } from 'date-fns'

export default {
  name: 'TimestampMessage',
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  computed: {
    /**
     * @returns {ChatTimestampMessagePayload}
     */
    payload() {
      return JSON.parse(this.message.payload)
    },
    textMessage() {
      const { timestamp } = this.payload

      return format(parseISO(timestamp), 'dd.MM.yyyy')
    },
  },
}
</script>

<style lang="scss" scoped>
.timestamp-message {
  margin: 10px auto;
  padding: 5px;
  .timestamp-message-message {
    font-size: 12px;
    font-weight: 500;
    color: #bbbfbf;
  }
}
</style>
