import Vue from 'vue'
import Vuex from 'vuex'

import consultation from '@/store/modules/consultation'
import auth from '@/store/modules/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isChat: false,
    isFullPage: false,
    isFixRight: true,
    isWrap: false,
  },
  mutations: {
    setIsChat(state, res) {
      state.isChat = res
    },
    setIsWrap(state, res) {
      state.isWrap = res
      state.isFullPage = false
      state.isFixRight = false
    },
    setIsFullPage(state, res) {
      state.isWrap = false
      state.isFullPage = res
      state.isFixRight = !res
    },
  },
  getters: {},
  modules: {
    namespaced: true,
    consultation,
    auth,
  },
})
