<template>
  <div class="calling">
    <div class="title">
      <span>Идет вызов...</span>
      <!--      <div class="icon call-end" @click="onStopCall">-->
      <!--        <IconCallEnd />-->
      <!--      </div>-->
    </div>

    <div class="preloader">
      <div class="push-pop loader">
        <div></div>
        <div></div>
      </div>
      <div class="push-out loader">
        <div></div>
        <div></div>
      </div>
    </div>
    <audio autoplay loop src="@/assets/audio/calling-sound.mp3" />
    <div class="icon call-end" @click="onStopCall">
      <IconCallEnd />
    </div>
  </div>
</template>

<script>
import IconCallEnd from '@/assets/svg/call-end.svg?inline'

export default {
  name: 'Calling',
  components: { IconCallEnd },
  methods: {
    onStopCall() {
      this.$emit('stop')
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-size: 20px;
  text-align: center;
  padding: 0 15px;
}

.loader {
  margin: 50px;
}

.push-pop {
  --duration: 0.85;
  height: 100px;
  width: 100px;
  position: relative;
  overflow: hidden;

  & > div:nth-of-type(1) {
    height: 20px;
    width: 20px;
    position: absolute;
    animation: push-pop-slide calc(var(--duration) * 1s) infinite alternate
      ease-in-out;
    transform: translate(0, -100%);
    top: 100%;
    left: 0;

    &:after {
      animation: push-pop-flip calc(var(--duration) * 1s) infinite alternate
        ease-in-out;
      background: #0d4cd3;
      content: '';
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }

  & > div:nth-of-type(2) {
    background: #0d4cd3;
    height: 30px;
    left: 50%;
    position: absolute;
    top: 100%;
    transform: translate(-50%, -100%);
    width: 20px;
  }

  // Use the pseudo elements for the shifting pillars
  &:after,
  &:before {
    animation: push-pop-pushed calc(var(--duration) * 1s) alternate infinite
      ease;
    background: #0d4cd3;
    bottom: 0;
    content: '';
    height: 40px;
    position: absolute;
    width: 20px;
  }

  &:before {
    animation: push-pop-pushed calc(var(--duration) * 1s) alternate-reverse
      infinite ease;
    left: 0;
  }

  &:after {
    right: 0;
  }
}

@keyframes push-pop-pushed {
  0%,
  72.5% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 100%);
  }
}

@keyframes push-pop-flip {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(0, -80px) rotate(90deg);
  }
  100% {
    transform: translate(0, 0) rotate(180deg);
  }
}

@keyframes push-pop-slide {
  to {
    transform: translate(0, -100%) translate(80px, 0);
  }
}

/**
  * push-out
  * lil push out effect, push one side out and one side in
  * @author jh3y
*/

.push-out {
  --duration: 0.85;
  height: 100px;
  width: 100px;
  position: relative;
  overflow: hidden;

  // Use the first div for shifting the block from side to side
  & > div:nth-of-type(1) {
    height: 20px;
    width: 20px;
    position: absolute;
    animation: push-out-slide calc(var(--duration) * 1s) infinite alternate
      ease-in-out;
    transform: translate(0, -100%);
    top: 100%;
    left: 0;

    &:after {
      animation: push-out-flip calc(var(--duration) * 1s) infinite alternate
        ease-in-out;
      background: #0d4cd3;
      content: '';
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }

  // Use the second for the staionary pillar
  & > div:nth-of-type(2) {
    background: #0d4cd3;
    height: 30px;
    left: 50%;
    position: absolute;
    top: 100%;
    transform: translate(-50%, -100%);
    width: 20px;
  }

  // Use the pseudo elements for the shifting pillars
  &:after,
  &:before {
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    animation-duration: calc(var(--duration) * 4s);
    background: #0d4cd3;
    bottom: 0;
    content: '';
    height: 40px;
    position: absolute;
    width: 20px;
  }

  &:after {
    animation-name: push-out-pushed-1;
    right: 0;
  }

  &:before {
    animation-name: push-out-pushed-2;
    left: 0;
  }
}

@keyframes push-out-pushed-1 {
  0%,
  18.125%,
  50%,
  68.125%,
  100% {
    transform: translate(0, 0);
  }
  25%,
  43.125%,
  75%,
  93.125% {
    transform: translate(0, 100%);
  }
}

@keyframes push-out-pushed-2 {
  0%,
  18.125%,
  50%,
  68.125%,
  100% {
    transform: translate(0, 100%);
  }
  25%,
  43.125%,
  75%,
  93.125% {
    transform: translate(0, 0);
  }
}

@keyframes push-out-flip {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(0, -80px) rotate(90deg);
  }
  100% {
    transform: translate(0, 0) rotate(180deg);
  }
}

@keyframes push-out-slide {
  to {
    transform: translate(0, -100%) translate(80px, 0);
  }
}

.call-end {
  width: 36px;
  height: 36px;
  border-radius: 50px;
  //margin-left: 2rem;
  margin: 1rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
  background: #e30958;
  svg {
    fill: #ffffff;
  }
}
</style>
