<template>
  <div class="chat">
    <div ref="chatInner" class="chat-inner custom-scrollbar">
      <MessageContainer
        v-for="message in messages"
        :key="message.id"
        :message="message"
        :files="files"
      />
      <Preloader v-if="isMessageSending" small dots />
    </div>
    <div class="controls">
      <div class="upload">
        <input
          ref="fileInput"
          :class="{ disabled: isMessageSending || isMessagesFetching }"
          type="file"
          multiple
          accept="*/*"
          @change="onFileUpload"
        />
        <IconPaperClip class="icon" />
      </div>
      <ResizableTextarea
        ref="resize"
        @messageSend="sendTextMessage"
        @inputText="getMessageText"
      />
      <div
        class="send"
        :class="{ disabled: isMessageSending || isMessagesFetching }"
        @click="sendTextMessage"
      >
        <IconSend class="icon" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { messageTypes } from '@/consts/const'

import ChatService, { chatService } from '@/services/chat/chat.service'

import ResizableTextarea from '@/components/form/ResizableTextarea'
import IconPaperClip from '@/assets/svg/paper-clip.svg?inline'
import IconSend from '@/assets/svg/send.svg?inline'

import MessageContainer from '@/components/chat/MessageContainer'
import Preloader from '@/components/base/Preloader'

export default {
  name: 'ConsultationChat',
  components: {
    Preloader,
    IconPaperClip,
    IconSend,
    ResizableTextarea,
    MessageContainer,
  },
  data() {
    return {
      text: '',

      isMessagesFetching: false,
      isMessageSending: false,
      rawMessages: [],
    }
  },
  computed: {
    ...mapState('consultation', ['consultation', 'files']),
    messages() {
      return chatService.addTimestamps(this.rawMessages)
    },
  },
  watch: {
    messages() {
      this.scrollToBottom()
    },
  },
  async mounted() {
    this.scrollToBottom()
    chatService.onMessage(this.consultation.Request.Id, async (message) => {
      await this.handleMessage(message)
      this.rawMessages.push(message)
    })

    try {
      this.isMessagesFetching = true
      this.rawMessages = await chatService.getList(this.consultation.Request.Id)
    } catch (e) {
      console.error('При загрузке сообщений что-то пошло не так')
    } finally {
      this.isMessagesFetching = false
    }
  },
  methods: {
    ...mapActions('consultation', [
      'uploadFiles',
      'getConsultationFiles',
      'getConsultationById',
    ]),
    getMessageText(text) {
      this.text = text
    },
    async sendMessage(payload) {
      try {
        this.isMessageSending = true
        const message = await chatService.sendMessage(payload)
        await this.handleMessage(message)
      } catch (e) {
        console.error('При отправке сообщения что-то пошло не так')
      } finally {
        this.isMessageSending = false
      }
    },
    async sendTextMessage() {
      if (this.text) {
        const payload = {
          consultationId: this.consultation.Request.Id,
          text: this.text,
          messageType: ChatService.CHAT_MESSAGE_TYPE.TEXT,
        }

        this.$refs.resize.$refs.textarea.textContent = ''
        this.text = ''

        await this.sendMessage(payload)
      }
    },
    async onFileUpload(e) {
      try {
        this.isMessageSending = true
        this.scrollToBottom()
        const files = [...e.target.files]
        this.$refs.fileInput.value = null
        const filenames = await this.uploadFiles(files).catch((e) => {
          console.error('An error occurred while files was uploading')
        })

        filenames.forEach((filename) => {
          this.sendMessage({
            consultationId: this.consultation.Request.Id,
            payload: JSON.stringify({ FileNames: [filename] }),
            messageType: ChatService.CHAT_MESSAGE_TYPE.FILE,
          })
        })
      } catch (e) {
        console.error('При отправке файла что-то пошло не так')
        this.isMessageSending = false
      }
    },
    async handleMessage(message) {
      if (message.messageType === ChatService.CHAT_MESSAGE_TYPE.FILE) {
        if (this.consultation.Request?.Folders?.FolderOther) {
          await this.getConsultationFiles()
        } else {
          this.getConsultationById(this.consultation.Request.Id)
        }
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.chatInner.scrollTo({
          top: this.$refs.chatInner.scrollHeight,
          behavior: 'smooth',
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .chat-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    padding: 0 15px;
    overflow-x: hidden;
    overflow-y: auto;

    ::v-deep.preloader {
      justify-content: flex-end;
      .dots {
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        .dot {
          width: 8px;
          height: 8px;
        }
      }
    }
  }

  .controls {
    display: flex;
    min-height: 45px;
    padding: 0 8px;
    border-top: 1px solid #dddddd;

    .upload {
      position: relative;
      cursor: pointer;
      width: 45px;
      height: 45px;
      margin-right: 25px;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        pointer-events: none;
        top: 0;
        left: 0;
        width: 21px;
        height: 21px;
        fill: #666664;
      }

      input {
        width: 45px;
        height: 45px;
        position: absolute;
        font-size: 0;
        opacity: 0;
        cursor: pointer;
        word-break: break-word;

        &::-webkit-file-upload-button {
          cursor: pointer;
        }

        &.disabled {
          pointer-events: none;
        }
      }
    }

    .send {
      position: relative;
      cursor: pointer;
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        pointer-events: none;
        top: 0;
        left: 0;
        width: 18px;
        height: 18px;
        fill: #666664;
      }
    }
  }
}
</style>
