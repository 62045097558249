import { CALL_TYPES } from '@/consts/const'
import { coreRequest } from '@/api'

export class WidgetApi {
  constructor(app) {
    this.app = app
    this.initiated = false
  }

  async initiate(params) {
    let data = null

    if (process.env.VUE_APP_ENVIRONMENT === 'development') {
      data = {
        token: params.token,
        consultation_id: params.consultation_id,
      }
    } else {
      const response = await coreRequest.auth({
        ...params,
        ticket: process.env.VUE_APP_ECP_TICKET,
        system_id: process.env.VUE_APP_ECP_SYSTEM_ID,
      })
      data = response.data
    }

    await this.app.store.dispatch('auth/init', data.token)

    await this.app.store.dispatch(
      'consultation/getConsultationById',
      data.consultation_id
    )
    this.initiated = true
  }

  makeCall(type) {
    if (!this.initiated) {
      throw new Error('Make initiate widget before')
    }

    if (Object.values(CALL_TYPES).findIndex((key) => key === type) === -1) {
      throw new Error('Invalid call type')
    }

    this.app.store.commit('setCallType', type)

    if (type !== CALL_TYPES.SIP) {
      let constraints = { audio: true }

      if (type === CALL_TYPES.VIDEO) {
        constraints = { ...constraints, video: true }
      }

      this.app.store.dispatch('webrtc/connect', constraints)
    } else {
      this.app.store.dispatch('sip/initSipCalling')
    }
  }

  stopCalling() {
    if (!this.app.store.state.isCalling) {
      throw new Error('Calling not in progress!')
    }

    if (this.app.store.state.callType === CALL_TYPES.SIP) {
      this.app.store.dispatch('sip/onStopSipCall')
    } else {
      this.app.store.dispatch('socket/initiateStopCall')
    }
  }
}
