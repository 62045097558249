import Vue from 'vue'
import App from './App.vue'
import dateTime from '@/filters/dateTime'

import vueCustomElement from 'vue-custom-element'
import 'document-register-element/build/document-register-element'
import '@/services/call/index'

import { WidgetApi } from '@/widget-api'

Vue.filter('dateTime', dateTime)

Vue.use(vueCustomElement)

Vue.config.productionTip = false

Vue.customElement('d-widget', App)

const dwidget = new WidgetApi(App)
window.dwidget = dwidget
