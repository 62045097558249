import * as SignalR from '@microsoft/signalr'
import * as Emitter from 'event-emitter'

const MESSAGE_TYPE = 'message'

class HubClient {
  constructor() {
    this.hub = null
    this.emitter = new Emitter()
  }

  async init(token) {
    if (this.hub) {
      console.error('Hub already initiated')
      return
    }

    if (!token) {
      throw new Error('Token is undefined!')
    }

    this.hub = new SignalR.HubConnectionBuilder()
      .withUrl(process.env.VUE_APP_HUB_SOCKET_URL, {
        accessTokenFactory() {
          return token
        },
        skipNegotiation: true,
        transport: SignalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build()

    this.hub.on(MESSAGE_TYPE, this.onMessage.bind(this))

    await this.hub.start()
  }

  onMessage(message) {
    const { type, data } = message

    this.emitter.emit(type, data)
  }

  on(type, cb) {
    this.emitter.on(type, cb)
  }
}

export const HubClientService = new HubClient()
