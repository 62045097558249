import Api from '@/api/api'

export default class ServiceApi extends Api {
  constructor() {
    super()
    this.client.defaults.baseURL = process.env.VUE_APP_OLD_API
  }

  registerProfileDevice() {
    return this.client.post('/v1/profile/devices', {
      DeviceId: '',
      ApplicationType: 'DoctorApp',
      ClientPlatform: 'WebBrowser',
      CertificateType: 0,
    })
  }

  async getConsultationRequest(id) {
    const { data } = await this.client.get('/v1/consultation/report', {
      params: {
        requestId: id,
      },
    })

    return data
  }

  async getUserProfile(personId) {
    const { data } = await this.client.get('/v1/profile/user_profile', {
      params: {
        personId,
      },
      errorMessage: 'Проблема при загрузке профиля пациента...',
    })
    return data
  }

  async addFileToConsultation(consultId, filename) {
    return this.client.put(
      `/v1/consultation/${consultId}/add-request-files/folderOther`,
      {
        fileNames: [filename],
      }
    )
  }

  async pushNewMessageNotification(consultId) {
    return await this.client.post(
      `/v1/chat/push-request-companion/${consultId}`
    )
  }
}
