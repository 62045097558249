<template>
  <div
    ref="textarea"
    class="custom-scrollbar resizable-textarea"
    contenteditable="true"
    @input="onInput"
    @keydown.enter="sendMessage"
  ></div>
</template>

<script>
export default {
  name: 'ResizableTextarea',
  props: {
    placeholder: {
      type: String,
      default: 'Напишите...',
    },
  },
  mounted() {
    this.$refs.textarea.value = ''
  },
  methods: {
    sendMessage() {
      return this.$emit('messageSend', this.messageSend)
    },
    onInput(e) {
      this.$emit('inputText', e.target.innerText)
    },
  },
}
</script>

<style lang="scss" scoped>
.resizable-textarea {
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  color: #666664;
  background: #ffffff;
  max-height: 120px;
  min-height: 40px;
  outline: none !important;
  overflow: auto;
  overflow-x: hidden;
  padding-top: 12px;
  resize: none;
}
</style>
