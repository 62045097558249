import { serviceRequest, fileRequest } from '@/api'
import { convertBlobToBase64 } from '@/helpers'
import { fileCategories, fileTypes } from '@/consts/const'
import { v4 as uuidv4 } from 'uuid'

const Consultations = {
  namespaced: true,
  state: {
    consultation: null,
    userProfile: null,

    files: [],
    isFetchingFiles: true,
    sliderImageIndex: null,
  },
  actions: {
    async getConsultationById({ commit, dispatch }, id) {
      commit('resetConsultationState')
      commit('resetUserProfile')

      const consultation = await serviceRequest.getConsultationRequest(id)
      commit('setConsultation', consultation)

      const userProfile = await serviceRequest.getUserProfile(
        consultation.Request.Profile.UserId
      )
      commit('setUserProfile', userProfile)

      if (consultation.Request?.Folders?.FolderOther) {
        await dispatch('getConsultationFiles')
      }
    },

    async getConsultationFiles({ state, commit }) {
      let folder = state.consultation.Request?.Folders?.FolderOther

      if (folder) {
        let listFiles = []
        const files = await fileRequest.getFilesByFolderName(folder)

        for await (const file of files) {
          const blob = await fileRequest.getFileByFilename(
            file.Name,
            file.MimeType
          )
          const base64 = await convertBlobToBase64(blob)
          listFiles.push({ ...file, base64, blob })
        }
        commit('setFiles', listFiles)
      }
    },
    async uploadFiles({ state, dispatch }, files) {
      let folder = state.consultation.Request.Folders.FolderOther
      const { Id: consultationId } = state.consultation.Request

      const mappedFiles = files.map((file) => {
        const fileType = file.type.split('/')[0]
        const category =
          fileCategories.find((type) => type === fileType) || 'document'
        return {
          id: uuidv4(),
          category,
          mimeType: file.type,
          file,
        }
      })

      const fileTag = fileTypes.OTHER

      const promises = mappedFiles.map((item) => {
        const { file, category, mimeType } = item
        const formData = new FormData()
        formData.append('file', file)
        return fileRequest.saveFile(formData, category, mimeType, fileTag.id)
      })

      const filenames = await Promise.all(promises)

      console.log('filenames', filenames)

      const addFilesPromises = await filenames.map((filename) => {
        return serviceRequest.addFileToConsultation(
          state.consultation.Request.Id,
          filename
        )
      })
      await Promise.all(addFilesPromises)

      if (!folder) {
        await dispatch('getConsultationById', consultationId)
      }
      return filenames
    },
  },
  mutations: {
    setConsultation(state, consultation) {
      state.consultation = consultation
    },
    setFiles(state, files) {
      state.files = files
    },
    startFetchingFiles(state) {
      state.isFetchingFiles = true
    },
    stopFetchingFiles(state) {
      state.isFetchingFiles = false
    },
    resetConsultationState(state) {
      state.consultation = null
      state.files = []
      state.sliderImageIndex = null
    },
    setUserProfile(state, userProfile) {
      state.userProfile = userProfile
    },
    resetUserProfile(state) {
      state.userProfile = null
    },
  },
  getters: {
    getFileByName: (state) => (filename) =>
      state.files.find((file) => file.Name === filename),
    getConsultationImages: (state) =>
      state.files.filter((file) => file.MimeType.includes('image')),
  },
}

export default Consultations
