<template>
  <div class="message-container" :data-position="messagePosition">
    <component :is="messageComponent" :message="message" :files="files" />
  </div>
</template>

<script>
import ChatService from '@/services/chat/chat.service'

import TextMessage from '@/components/chat/messages/TextMessage'
import FileMessage from '@/components/chat/messages/FileMessage'
import TimestampMessage from '@/components/chat/messages/TimestampMessage'
import CallMessage from '@/components/chat/messages/CallMessage'

const MESSAGE_POSITION = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
}

export default {
  name: 'MessageContainer',
  components: {
    TextMessage,
    FileMessage,
    TimestampMessage,
    CallMessage,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    files: {
      type: Array,
      required: true,
    },
  },
  computed: {
    messageComponent() {
      return `${this.message.messageType}-message`
    },
    isSystem() {
      const { messageType } = this.message
      const { CALL, TIMESTAMP } = ChatService.CHAT_MESSAGE_TYPE

      return messageType === CALL || messageType === TIMESTAMP
    },
    isIncome() {
      return this.message.member?.memberType === ChatService.MEMBER_TYPE.PATIENT
    },
    messagePosition() {
      if (this.isSystem) {
        return MESSAGE_POSITION.CENTER
      }
      if (this.isIncome) {
        return MESSAGE_POSITION.LEFT
      }
      return MESSAGE_POSITION.RIGHT
    },
  },
}
</script>

<style lang="scss" scoped>
.message-container {
  max-width: 80%;

  &[data-position='left'] {
    align-items: flex-start;
    margin-right: auto;
  }
  &[data-position='right'] {
    align-items: flex-end;
    margin-left: auto;
  }
  &[data-position='center'] {
    align-items: center;
    margin: 0 auto;
  }
}
</style>
