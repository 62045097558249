class ChatApi {
  constructor(client) {
    this.client = client.instance
  }

  static URL_PREFIX =
    process.env.VUE_APP_NEW_API + '/v3/consultation/chat/message'

  /**
   * @param {number | string} consultationId
   * @returns {Promise<Array<ChatMessageModel>>}
   */
  list(consultationId) {
    return this.client.get(ChatApi.URL_PREFIX + '/list', {
      params: {
        consultationId,
      },
    })
  }

  /**
   * @param {ChatMessageSendDTO} message
   * @returns {Promise<ChatMessageModel>}
   */
  send(message) {
    return this.client.post(ChatApi.URL_PREFIX + '/send', message)
  }
}

export default ChatApi
